import { Tile } from '../structure/Tile'

import { getHref } from '../../lib/helper/helper'
import { TileImage } from '../structure/TileImage'
import { GLOBAL_FALLBACK_IMAGE } from '../../lib/constants'
import { GooeyText } from '../shared/GooeyText'
import { useTranslation } from 'next-i18next'
import Link from '../overwrites/Link'

type Props = {
  item: Country | State | Region | MajorLocation
  type?: 'location'
  isSlider?: boolean
}

export function LocationTile({ item, type, isSlider }: Props) {
  const { t, i18n } = useTranslation('common')
  const { name, coverImage, number_activities } = item as any

  const numberToShow = number_activities
  const stringToShow = number_activities > 1 ? 'activities' : 'activity'

  return (
    <Link href={getHref(item, i18n.language as AppLocale)} passHref>
      <Tile type={type || 'location'} isSlider={isSlider}>
        <TileImage
          image={coverImage || GLOBAL_FALLBACK_IMAGE}
          width={286 * 1.5}
          height={158 * 1.5}
          alt={name + t('tile') + ' - SIGHT2SIGHT'}
          title={name + t('tile') + ' - SIGHT2SIGHT'}
        />
        <div className="absolute bottom-3 left-2 right-2 z-40">
          <GooeyText>
            <span className="text-basis text-sm font-medium">{name}</span>
            {!!numberToShow && numberToShow > 0 && (
              <span className="text-basis text-xs">
                <br />
                {numberToShow} {t(stringToShow)}
              </span>
            )}
          </GooeyText>
        </div>
      </Tile>
    </Link>
  )
}
