import { PortableTextReactComponents } from '@portabletext/react'
import Link from '../../components/overwrites/Link'
import { replaceWildcards } from './helper'
import { IdealImage } from '../../components/shared/IdealImage'

const replaceWildcardsInChildren = (children: React.ReactNode) => {
  if (typeof children === 'string') {
    return replaceWildcards(children)
  } else if (Array.isArray(children)) {
    return children.map((child) => replaceWildcards(child))
  }
  return children
}

export const PORTABLE_COMPONENTS: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => (
      <p className="mb-2 text-sm">{replaceWildcardsInChildren(children)}</p>
    ),
    h1: ({ children }) => (
      <h1 className="text-4xl font-medium mt-1 mb-3">
        {replaceWildcardsInChildren(children)}
      </h1>
    ),
    h2: ({ children }) => (
      <h2 className="text-2xl font-medium mb-3">
        {replaceWildcardsInChildren(children)}
      </h2>
    ),
    h3: ({ children }) => (
      <h3 className="text-xl font-medium mb-1 mt-5">
        {replaceWildcardsInChildren(children)}
      </h3>
    ),
    h4: ({ children }) => (
      <h4 className="text-xl font-semibold my-3">
        {replaceWildcardsInChildren(children)}
      </h4>
    ),
    h5: ({ children }) => (
      <h5 className="text-lg font-semibold my-2">
        {replaceWildcardsInChildren(children)}
      </h5>
    ),
    h6: ({ children }) => (
      <h6 className="text-base font-semibold my-2">
        {replaceWildcardsInChildren(children)}
      </h6>
    ),
    blockquote: ({ children }) => (
      <blockquote className="pl-4 border-l-4 border-gray-400 italic my-1">
        {replaceWildcardsInChildren(children)}
      </blockquote>
    ),
  },
  marks: {
    link: ({ children, value }: { children: React.ReactNode; value?: any }) => {
      if (!value) return <>{children}</>
      const isExternal = !value.href.startsWith('/')
      const href =
        isExternal &&
        !value.href.startsWith('http://') &&
        !value.href.startsWith('https://')
          ? `http://${value.href}`
          : value.href
      const linkAttributes = {
        href,
        rel: isExternal ? 'noreferrer noopener' : undefined,
        target: isExternal ? '_blank' : undefined,
      }
      return (
        <Link
          {...linkAttributes}
          className="text-blue-600 underline hover:text-blue-800 visited:text-purple-600"
        >
          {replaceWildcardsInChildren(children)}
        </Link>
      )
    },
  },
  list: {
    bullet: ({ children }) => (
      <ul className="list-disc text-sm pl-8 mb-4">
        {replaceWildcardsInChildren(children)}
      </ul>
    ),
    number: ({ children }) => (
      <ol className="list-decimal text-sm pl-8 mb-4">
        {replaceWildcardsInChildren(children)}
      </ol>
    ),
  },
  listItem: {
    bullet: ({ children }) => (
      <li className="mb-1">{replaceWildcardsInChildren(children)}</li>
    ),
    number: ({ children }) => (
      <li className="mb-1">{replaceWildcardsInChildren(children)}</li>
    ),
  },
  types: {
    image: ({ value }) => {
      return (
        <div className="relative w-full rounded-lg overflow-hidden">
          <IdealImage
            image={{ image: value, _type: 'custom_image' }}
            autoheight
          />
        </div>
      )
    },
  },
}
