import { DefaultSeo, NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { urlForImage } from '../../lib/sanity.image'
import { GLOBAL_FALLBACK_IMAGE } from '../../lib/constants'
import { replaceWildcards } from '../../lib/helper/helper'
import Script from 'next/script'
import PageSchema from './PageSchema'

type Props = {
  settings?: Settings
  meta_title?: string
  meta_description?: string
  alternates?: { hrefLang: string; href: string }[]
  item?:
    | Product
    | Category
    | State
    | ReducedMajorLocation
    | MajorLocation
    | Country
    | Region
  products?: ReducedProduct[]
  coverImage?: Image
  wildcards?: Wildcards
  faqs?: FAQ[]
}

export default function PageHead({
  settings,
  meta_title,
  meta_description,
  alternates,
  item,
  coverImage,
  wildcards = {},
  faqs,
}: Props) {
  const { asPath } = useRouter()
  const { i18n } = useTranslation('common')

  const {
    description: descriptionSettings,
    title: titleSettings,
    banner: bannerSettings,
  } = settings || {}
  const description = meta_description || descriptionSettings
  const title = meta_title || titleSettings
  const banner = coverImage || bannerSettings || GLOBAL_FALLBACK_IMAGE
  const ogImageUrl = urlForImage(banner?.image || banner).url()
  const currentUrl = `${process.env.NEXT_PUBLIC_URL}${i18n.language === 'en' ? '' : '/' + i18n.language}${asPath}`

  return (
    <>
      <DefaultSeo
        title="SIGHT 2 SIGHT"
        description="Example description"
        openGraph={{
          type: 'website',
          locale: i18n.language,
          url: currentUrl,
          site_name: 'SIGHT 2 SIGHT',
          images: [
            {
              url: ogImageUrl,
              alt: 'SIGHT 2 SIGHT',
            },
          ],
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <NextSeo
        title={replaceWildcards(title, wildcards)}
        description={replaceWildcards(description, wildcards)}
        openGraph={{
          title: replaceWildcards(title, wildcards),
          description: replaceWildcards(description, wildcards),
          images: [
            {
              url: ogImageUrl,
              alt: replaceWildcards(title, wildcards),
            },
          ],
        }}
        canonical={currentUrl}
        languageAlternates={alternates}
      />
      <PageSchema item={item} settings={settings} faqs={faqs} />
      <Script
        src="https://consent.cookiebot.com/uc.js"
        id="Cookiebot"
        strategy={'lazyOnload'}
        data-cbid="360903dd-61b5-4e54-bf52-e530b17b330e"
        type="text/javascript"
      />
    </>
  )
}
