import React, { useEffect, useCallback, useState, useRef } from 'react'
import { EngineType } from 'embla-carousel/components/Engine'
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel'
import { type SanityClient } from 'next-sanity'

import useEmblaCarousel from 'embla-carousel-react'
import { CategoryTile } from '../tiles/CategoryTile'
import { LocationTile } from '../tiles/LocationTile'
import { useIsMobile } from '../../lib/hooks/useIsMobile'
import { ProductTile } from '../tiles/ProductTile'
import { SkeletonTile } from './SkeletonTile'
import { useTranslation } from 'next-i18next'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { getClient } from '../../lib/sanity.client'

type Props = {
  labelKey?: string
  loadWith: (
    locale: AppLocale,
    client: SanityClient,
    offset?: number,
  ) => Promise<
    | Country[]
    | State[]
    | City[]
    | Region[]
    | MajorLocation[]
    | Category[]
    | Product[]
    | ReducedProduct[]
  >
  onIndexChange?: (index: number) => void
  options?: EmblaOptionsType
  location?: string
  type?: 'product' | 'location' | 'category'
  imageWidth?: number
  keepLocation?: boolean
  imageHeight?: number
  isMobileOverwrite?: boolean
}

const TileComponentMapping = {
  country: LocationTile,
  city: LocationTile,
  category: CategoryTile,
  region: LocationTile,
  state: LocationTile,
  product: ProductTile,
}

const TileSlider = ({
  labelKey,
  loadWith,
  onIndexChange,
  location,
  type = 'location',
  keepLocation,
  isMobileOverwrite,
}: Props) => {
  const { t, i18n } = useTranslation('common')
  const isMobile = useIsMobile()
  const client = getClient()
  const [canScrollPrev, setCanScrollPrev] = useState(false)
  const [canScrollNext, setCanScrollNext] = useState(true)
  const scrollListenerRef = useRef<() => void>(() => undefined)
  const listenForScrollRef = useRef(true)
  const hasMoreToLoadRef = useRef(true)

  const [slides, setSlides] = useState([])
  const initialNumber = useRef(0)
  const [loadingMore, setLoadingMore] = useState(true)
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: 'start',
      skipSnaps: true,
      watchSlides: (emblaApi) => {
        const reloadEmbla = (): void => {
          const oldEngine = emblaApi.internalEngine()

          emblaApi.reInit()
          const newEngine = emblaApi.internalEngine()
          const copyEngineModules: (keyof EngineType)[] = [
            'location',
            'target',
            'scrollBody',
          ]
          copyEngineModules.forEach((engineModule) => {
            Object.assign(newEngine[engineModule], oldEngine[engineModule])
          })

          newEngine.translate.to(oldEngine.location.get())
          const { index } = newEngine.scrollTarget.byDistance(0, false)
          newEngine.index.set(index)
          newEngine.animation.start()

          setLoadingMore(false)
          listenForScrollRef.current = true
        }

        const reloadAfterPointerUp = (): void => {
          emblaApi.off('pointerUp', reloadAfterPointerUp)
          reloadEmbla()
        }

        const engine = emblaApi.internalEngine()

        if (hasMoreToLoadRef.current && engine.dragHandler.pointerDown()) {
          const boundsActive = engine.limit.reachedMax(engine.target.get())
          engine.scrollBounds.toggleActive(boundsActive)
          emblaApi.on('pointerUp', reloadAfterPointerUp)
        } else {
          reloadEmbla()
        }
      },
    },
    [WheelGesturesPlugin()],
  )

  useEffect(() => {
    loadWith(i18n.language as AppLocale, client).then((fetchedItems) => {
      setSlides(fetchedItems)
      initialNumber.current = fetchedItems.length
      setLoadingMore(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, loadWith])

  const onScroll = useCallback(
    (emblaApi: EmblaCarouselType) => {
      if (!listenForScrollRef.current) return

      setLoadingMore((loadingMore) => {
        const lastSlide = emblaApi.slideNodes().length - 4
        const lastSlideInView = emblaApi.slidesInView().includes(lastSlide)
        const loadMore = !loadingMore && lastSlideInView
        if (loadMore && emblaApi.slideNodes().length % 10 === 0) {
          listenForScrollRef.current = false
          loadWith(
            i18n.language as AppLocale,
            client,
            emblaApi.slideNodes().length,
          ).then((newSlides) => {
            if (newSlides && newSlides.length > 0) {
              setSlides((currentSlides) => {
                return currentSlides.concat(newSlides)
              })
            }
          })
        }

        return loadingMore || lastSlideInView
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language, loadWith, slides],
  )

  const addScrollListener = useCallback(
    (emblaApi: EmblaCarouselType) => {
      scrollListenerRef.current = () => onScroll(emblaApi)
      emblaApi.on('scroll', scrollListenerRef.current)
    },
    [onScroll],
  )

  useEffect(() => {
    if (!emblaApi) return
    addScrollListener(emblaApi)
    const onResize = () => emblaApi.reInit()
    window.addEventListener('resize', onResize)
    emblaApi.on('destroy', () => window.removeEventListener('resize', onResize))
  }, [emblaApi, addScrollListener])

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    const selectedIndex = emblaApi.selectedScrollSnap()
    onIndexChange?.(selectedIndex)
  }, [emblaApi, onIndexChange])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSelect)
      emblaApi.on('reInit', onSelect)
      emblaApi.on('select', () => {
        setCanScrollPrev(emblaApi.canScrollPrev())
        setCanScrollNext(emblaApi.canScrollNext())
      })
    }
  }, [emblaApi, onSelect])

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev()
      setCanScrollPrev(emblaApi.canScrollPrev())
    }
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext()
      setCanScrollNext(emblaApi.canScrollNext())
    }
  }, [emblaApi])

  const renderLoadingTiles = () => {
    const numberOfSkeletons = 9
    return Array.from({ length: numberOfSkeletons }, (_, index) => (
      <div className="embla__slide" key={index}>
        <SkeletonTile type={type} isSlider={true} />
      </div>
    ))
  }

  const renderTile = (item, index) => {
    const TileComponent = TileComponentMapping[item._type] || null
    if (TileComponent) {
      return (
        <div className="embla__slide" key={index}>
          <TileComponent
            key={index}
            item={item}
            isSlider
            type={type}
            keepLocation={keepLocation}
          />
        </div>
      )
    }
    return null
  }

  const scrollButton = (direction: 'prev' | 'next') => {
    const classes = direction === 'prev' ? 'left-0' : 'right-0 translate-x-3'
    return (
      <button
        onClick={direction === 'prev' ? scrollPrev : scrollNext}
        className={`absolute ${classes} top-1/2 transform -translate-y-1/2 z-10 bg-white w-8 h-8 bg-white rounded-full flex items-center justify-center border`}
        aria-label={
          direction === 'prev' ? 'Go to previous slide' : 'Go to next slide'
        }
      >
        <span
          className={`fa-solid fa-arrow-left ${direction === 'prev' ? '' : ' rotate-180'}`}
        />
      </button>
    )
  }

  if (!loadingMore && slides.length === 0) return null

  return (
    <div className="w-full">
      {slides.length === 0 && !loadingMore ? null : (
        <div className="text-xl text-basis font-medium ml-4 xs:ml-0 mt-8 text-left">
          {t(labelKey, {
            amount: !!initialNumber.current ? initialNumber.current : '',
            location,
          })}
        </div>
      )}
      <div
        className="embla"
        style={
          isMobileOverwrite || isMobile
            ? {}
            : { transform: 'translateX(-1rem)' }
        }
      >
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container relative">
            {slides.length === 0
              ? loadingMore
                ? renderLoadingTiles()
                : null
              : slides.map((item, index) => renderTile(item, index))}
          </div>
        </div>
        {!(isMobileOverwrite || isMobile) && slides.length > 1 && (
          <>
            {canScrollPrev && scrollButton('prev')}
            {canScrollNext && scrollButton('next')}
          </>
        )}
      </div>
    </div>
  )
}
export default TileSlider
