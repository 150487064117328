import { Tile } from '../structure/Tile'

import { getHref } from '../../lib/helper/helper'
import { TileImage } from '../structure/TileImage'
import { GooeyText } from '../shared/GooeyText'
import { useTranslation } from 'next-i18next'
import Link from '../overwrites/Link'

type Props = {
  item: Category
  type?: 'category'
  keepLocation?: boolean
}
export function CategoryTile({ item, type, keepLocation }: Props) {
  const { t, i18n } = useTranslation('common')
  const { name, coverImage, number_activities, location } = item
  const { name: locationName } = location
  if (!name) return null
  return (
    <Link href={getHref(item, i18n.language as AppLocale)} passHref>
      <Tile type={type || 'category'}>
        <TileImage
          image={coverImage}
          width={174 * 1.5}
          height={254 * 1.5}
          alt={name + t('tile') + ' - SIGHT2SIGHT'}
          title={name + t('tile') + ' - SIGHT2SIGHT'}
        />
        <div className="absolute bottom-3 left-2 right-2 z-40">
          <GooeyText>
            <span className="text-basis text-sm font-semibold">
              {keepLocation ? name : name.replace(locationName, '')}
            </span>
            {!!number_activities && (
              <span className="text-basis text-xs">
                <br />
                {number_activities} {t('activities')}
              </span>
            )}
          </GooeyText>
        </div>
      </Tile>
    </Link>
  )
}
