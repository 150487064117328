import {
  getTileHeight,
  getTileMinWidth,
  getTileWidth,
} from '../../lib/helper/helper'

type Props = {
  children: React.ReactNode
  type?: 'category' | 'location' | 'product'
  isSlider?: boolean
}

export function Tile({ children, type = 'location', isSlider }: Props) {
  return (
    <div
      className={`relative group flex flex-col items-stretch ${getTileWidth(type, isSlider)} ${getTileHeight(type, isSlider)} justify-between max-w-md rounded-lg border overflow-hidden`}
      style={{ minWidth: getTileMinWidth(type, isSlider) }}
    >
      {children}
    </div>
  )
}
