import { useTranslation } from 'next-i18next'
import React from 'react'
import { HalfStar, Star } from './ReviewStar'

type Props = {
  stars?: number
  numberOfReviews?: number
  nameLocation?: string
  nameEntity?: string
}

export const BigReviews = ({
  stars,
  numberOfReviews,
  nameLocation,
  nameEntity,
}: Props) => {
  const { t } = useTranslation('common')
  if (!stars || !numberOfReviews || !nameLocation) {
    return null
  }
  const totalStars = 5
  const roundedStars = Math.round(stars * 2) / 2
  const fullStars = Math.floor(roundedStars)
  const halfStar = roundedStars % 1 !== 0 ? 1 : 0
  const emptyStars = totalStars - fullStars - halfStar
  const flooredReviews =
    numberOfReviews > 10
      ? Math.floor(numberOfReviews / 10) * 10
      : numberOfReviews // Floor to the last digit

  if (!stars) {
    return null
  }
  const renderNumberOfReviews =
    numberOfReviews && !isNaN(numberOfReviews) && flooredReviews

  return (
    <div className="mx-0 text-sm">
      {nameEntity
        ? t('big-reviews-1-1', {
            entity: nameEntity,
          })
        : t('big-reviews-1-2', {
            location: nameLocation,
          })}
      <span className="text-primary whitespace-nowrap">
        {' '}
        {renderNumberOfReviews}
      </span>
      {t('big-reviews-2')}
      <span className="mx-1" style={{ color: '#ebb305' }}>
        {(Math.round(stars * 10) / 10).toFixed(1)}
      </span>
      <span className="inline-block">
        <span className="flex items-center">
          {[...Array(fullStars)].map((_, index) => (
            <Star key={index} fill="#ebb305" /> // Full star
          ))}
          {halfStar > 0 && <HalfStar key="half-star" />}
          {[...Array(emptyStars)].map((_, index) => (
            <Star key={`empty-${index}`} fill="none" /> // Empty star
          ))}
        </span>
      </span>
      {t('big-reviews-3')} {t('big-reviews-4')}
    </div>
  )
}
