import { IdealImage } from '../shared/IdealImage'

type Props = {
  image: Image
  width?: number
  height?: number
  alt: string
  title: string
}

export function TileImage(props: Props) {
  return (
    <div className="relative w-full overflow-hidden flex-grow">
      <div className="relative w-full h-full overflow-hidden flex-grow transform group-hover:scale-110 transition-transform duration-300 ease-in-out z-20">
        <IdealImage {...props} />
      </div>
    </div>
  )
}
