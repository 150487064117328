import React from 'react'
import {
  getTileHeight,
  getTileMinWidth,
  getTileWidth,
} from '../../lib/helper/helper'

type Props = {
  type: 'product' | 'location' | 'category'
  isSlider?: boolean
}

export function SkeletonTile({ type, isSlider }: Props) {
  return (
    <div
      className={`bg-gray-200 ${getTileWidth(type, isSlider)} ${getTileHeight(type, isSlider)} justify-between max-w-md rounded-lg border overflow-hidden`}
      style={{ minWidth: getTileMinWidth(type, isSlider) }}
    >
      <div className="skeleton-shine"></div>
    </div>
  )
}
